import React from 'react';
import PropTypes from 'prop-types';

const ResearchSection = ({ children, title, image, orientation }) => (
  <div className={`flex flex-col ${orientation === 'right' ? 'lg:flex-row-reverse' : 'lg:flex-row'} mb-8`}>
    <div className='lg:w-3/5'>
      <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl leading-very-tight mb-4'>
        {title}
      </h2>

      <div className='font-sans font-normal text-grey leading-snug'>
        {children}
      </div>
    </div>

    <div className='lg:w-2/5 text-center flex justify-center items-center'>
      <img src={image} alt={title} className='flex-none mb-4 h-64' />
    </div>
  </div>
);

ResearchSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  orientation: PropTypes.string,
};

ResearchSection.defaultProps = {
  image: '',
  title: '',
  orientation: '',
};

export default ResearchSection;
