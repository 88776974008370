import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/our-science/hero';
import Science from '../components/our-science/science';

const OurScience = () => (
  <Layout>
    <SEO
      title='Our Science'
      description=''
    />
    <Hero />
    <Science />
  </Layout>
);

export default OurScience;
