import React from 'react';

const Hero = () => (
  <div className='our-science-hero'>
    <div className='container mx-auto pt-24 lg:pt-32 pb-16 px-8'>
      <div className='lg:w-2/5'>
        <h1 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
          Our Science
        </h1>

        <p className='font-sans font-normal text-white mb-4'>
          The NL Genome Project is a population genetics study. By combining genetic and health information with advanced statistical and computational analysis, we hope to use insights from Newfoundland and Labrador’s unique population to discover better, safer medicines and improve how we treat and prevent diseases.
        </p>

        <h4 className='font-soft font-semibold text-blue-900 text-lg leading-snug mb-4'>
          But, what is our genome? Why study our genetics?
        </h4>
      </div>
    </div>
  </div>
);

export default Hero;
