import React from 'react';
import Section from './science/section';
import spitImage from '../../images/saliva-sample.png';
import dnaImage from '../../images/dna.png';
import researchImage from '../../images/research.png';

const Science = () => (
  <>
    <div className='container mx-auto px-8 py-16'>
      <div className='flex flex-col justify-around items-center lg:flex-row mb-8 lg:w-3/4 mx-auto'>
        <div className='lg:w-3/5'>
          <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl leading-very-tight mb-4'>
            Let’s learn more about genomics and DNA
          </h2>

          <div className='font-sans font-normal text-grey leading-snug'>
            <p className='mb-4'>
              DNA is the unique code, or instruction manual, that helps make you unique. It tells your body how to grow, develop and repair itself. Your DNA is made up of four chemicals, which we represent by the letters A, C, T, and G.
            </p>
          </div>
        </div>

        <div className='lg:w-2/5 text-center flex justify-center items-center'>
          <img src={dnaImage} alt='DNA' className='flex-none mb-4 h-64' />
        </div>
      </div>

      <p className='font-sans font-semibold text-blue-900 text-xl lg:text-2xl leading-snug lg:w-3/4 mx-auto text-center mb-20'>
        Your genetic code is all the DNA in your body and is 3.2 billion letters long - this is called your genome. Your genome is 99.9% the same as everyone else’s. The 0.1% difference, based on the order of your DNA, is what makes you special and unique.
      </p>

      <Section title='What does your genome tell us?' image={spitImage} orientation='right'>
        <p className='mb-4'>
          Certain strings of letters in your genome are called genes and they tell us things like hair colour and how tall you will be. Your genes can also tell us more serious things like if you have a certain disease, or risk of developing various health conditions. You have about 22,000 genes in your body and the study of your genes is called genetics.
        </p>
      </Section>

      <Section title='Our Research' image={researchImage} orientation='right'>
        <p className='mb-4'>
          With the NL Genome Project, we’ll first extract DNA from your saliva and then use incredible technology to analyze your genetic code. We will start by performing advanced genotyping of all participants’ DNA.
        </p>

        <p className='mb-4'>
          While genotyping doesn’t read everything in your genome, it does provide an overall view of genetic changes scientists have already discovered. This will enable us to perform our research and return findings to participants that choose to receive them. Over time, we will perform whole genome sequencing on participants to read nearly all 3 billion letters of their genomes, and to look for all the changes and potentially discover new insights into people’s genetic variations.
        </p>

        <p className='mb-4'>
          Now that we know all about your genome, what next? Well, by combining and studying lots of interesting people’s genetic codes, medical records, and health information, we can look for patterns and genetic changes that can alter the function of genes (almost like a typo in our genome) that might explain why some people are more likely to get sick, or why some medicines work better on some people, but not others. These findings could be significant, and we hope will help Sequence Bio and approved partners identify opportunities for the development of better, safer treatments.
        </p>

        <p className='mb-4'>
          Like other health research, there is no guarantee the NL Genome Proejct will result in any significant findings.
        </p>
      </Section>

    </div>
  </>
);

export default Science;
